body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  background-color:#F2DCC9 ;
}

input[type="file"] {
  display: none;
}

input {
padding: 0;
background: none;
border: none;
border-radius: 0;
outline: none;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
font-family: "Gowun Batang";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 108.333% */
    letter-spacing: 0.46px;
    text-transform: uppercase; 

}


::placeholder {
  font-family: "Gowun Batang";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 108.333% */
    letter-spacing: 0.46px;
    text-transform: uppercase; 
    color: var(--type_teal, #025159);
 
}
